import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/jerry/Code/Jerrytron/jerrytron-com/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Writing Samples`}</h1>
    <h2>{`Interactive Fiction for `}<a parentName="h2" {...{
        "href": "/projects/a-maze-magazine/"
      }}>{`A MAZE Magazine`}</a></h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/if/eyes-that-watch.html"
        }}>{`Eyes That Watch`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/if/just-one-more-thing.html"
        }}>{`Just One More Thing Before You Go...`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/if/three-lives.html"
        }}>{`Three Lives`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/if/tamagotchi-life.html"
        }}>{`A Tamagotchi Life`}</a></li>
    </ul>
    <h2>{`Interactive Fiction for `}<a parentName="h2" {...{
        "href": "/projects/choosatron/"
      }}>{`The Choosatron`}</a></h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/if/another-day-at-the-mia.html"
        }}>{`Another Day at the MIA`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/if/chandos.html"
        }}>{`The Chandos`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/if/finding-your-shadow.html"
        }}>{`Finding Your Shadow`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/if/minnedemo-or-bust.html"
        }}>{`MinneDemo or Bust!`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/if/no-brakes-valet.html"
        }}>{`No Brakes Valet`}</a>
        {/* * [Revolver](/if/revolver.html) */}
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/if/spy-who-killed-me.html"
        }}>{`The Spy Who Killed Me`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/if/starwhal-just-the-tip.html"
        }}>{`Starwhal: Just the Tip`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/if/thanksgiving-of-despair.html"
        }}>{`Thanksgiving of Despair`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/if/wormsign.html"
        }}>{`Wormsign`}</a></li>
    </ul>
    {/* <Link to="/if/another-day-at-the-mia.html">Another Day at the MIA</Link>
     <Link to="/if/chandos.html">The Chandos</Link>
     <Link to="/if/eyes-that-watch.html">Eyes That Watch</Link>
     <Link to="/if/finding-your-shadow.html">Finding Your Shadow</Link>
     <Link to="/if/just-one-more-thing.html">Just One More Thing Before You Go...</Link>
     <Link to="/if/minnedemo-or-bust.html">MinneDemo or Bust!</Link>
     <Link to="/if/no-brakes-valet.html">No Brakes Valet</Link>
     <Link to="/if/revolver.html">Revolver</Link>
     <Link to="/if/spy-who-killed-me.html">The Spy Who Killed Me</Link>
     <Link to="/if/starwhal-just-the-tip.html">Starwhal: Just the Tip</Link>
     <Link to="/if/tamagotchi-life.html">A Tamagotchi Life</Link>
     <Link to="/if/thanksgiving-of-despair.html">Thanksgiving of Despair</Link>
     <Link to="/if/three-lives.html">Three Lives</Link>
     <Link to="/if/wormsign.html">Wormsign</Link> */}

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      